<template>
  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color,
    }"
    class="app-admin-wrap-layout-6"
  >
    <div
      v-if="loading"
      class="loader"
      :class="isContentVisible ? 'sub-loader': ''"
    >
      <div class="logo">
        <img
          src="@/assets/images/logo-circle.svg"
          height="35"
          style="background: #2b2f44; border-radius: 50%;"
        >
      </div>
      <div
        v-if="isContentVisible"
        class="mt-3 purple--text text--lighten-5"
      >
        {{ loadingText }}
      </div>
    </div>

    <header-element />

    <side-bar />

    <base-view v-if="isContentVisible" />

    <reset-password-dialog
      v-model="isShowResetPasswordDialog"
      @change-password="resetPassword"
    />
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'

  import util from '@/utils'
  import {
    showSuccessDialog,
    showErrorDialog,
  } from '@/utils/swalDialog'

  export default {
    components: {
      SideBar: () => import('./Sidebar'),
      BaseView: () => import('./View'),
      HeaderElement: () => import('./Header.vue'),
      ResetPasswordDialog: () => import('./ResetPasswordDialog.vue'),
    },

    data () {
      return {
        isContentVisible: false,
        isShowResetPasswordDialog: false,
      }
    },

    computed: {
      ...mapGetters(['loading', 'loadingText']),

      theme () {
        return this.$vuetify.theme.dark ? 'dark' : 'light'
      },
    },

    mounted () {
      this.$store.dispatch('setLoading', true)
      this.$store
        .dispatch('me')
        .then((res) => {
          this.isContentVisible = true
          this.$store.dispatch('setLoading', false)

          if (res.role.includes('user')) {
            this.$router.push('/orders')
          }
          this.isShowResetPasswordDialog = res.isRequireResetPassword
        })
        .catch(() => {
          localStorage.clear()
          window.location.href = '/'
        })
    },

    methods: {
      resetPassword (password) {
        this.$store.dispatch('setLoading', true)
        this.$store
          .dispatch('updatePassword', {
            password,
          })
          .then((res) => {
            this.isShowResetPasswordDialog = false
            showSuccessDialog(this.$t('userAuth.restPasswordSuccessComment'))
          })
          .catch((error) => {
            const errorText = util.getErrorResponse(error)
            showErrorDialog(this.$t('common.error'), errorText)
          })
          .finally(() => {
            this.$store.dispatch('setLoading', false)
            this.isShowResetPasswordDialog = false
          })
      },
    },
  }
</script>

<style lang="scss">
.app-admin-wrap-layout-6 {
  .sidebar-six {
    &.theme--dark.v-navigation-drawer {
      background-color: #111827;
    }
  }

  .loader {
    z-index: 99999;

    &.sub-loader {
      background-color: #242939cc;
      display: flex;
      flex-direction: column;
    }
  }
}
div.theme--dark .v-navigation-drawer {
  background-color: #272727 !important;
}
</style>
